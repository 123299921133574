import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

import App from '@/App.vue'
import i18n from '@/i18n'
import router from '@/router'

import '@/assets/scss/main.scss'

/* Font Awesome */

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars,
  faBowlingBall,
  faDeleteLeft,
  faEnvelope,
  faFileContract,
  faMagnifyingGlass,
  faPhone,
  faSpinner,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faBars,
  faBowlingBall,
  faDeleteLeft,
  faEnvelope,
  faFileContract,
  faMagnifyingGlass,
  faPhone,
  faSpinner,
  faXmark,
)

/* Creating the app */

const app = createApp(App)
const router_ = router()

if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    app,
    dsn: 'https://765811c5365ffd3fdb82d605aa57f233@o4506190267744256.ingest.sentry.io/4506190327119872',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          new URL(process.env.VUE_APP_BACKEND_API_URL).hostname,
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router_),
      }),
      new Sentry.Replay(),
    ],
    environment: 'production',
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

app
  .use(createPinia())
  .use(router_)
  .use(i18n)
  .component('FontAwesomeIcon', FontAwesomeIcon)
  .mount('#app')
